<template>
  <div>
    <!--begin::User-->
    <h3 class="card-label">
      {{ $t("add_subscribers") }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>
    <div class="card card-custom">
      <div class="card-body row">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t("categories.services") }}</h6>
            <button
                class="btn btn-primary mr-2"
                type="button"
                @click="addToRepeater"
            >
              {{ $t("add_more") }}
            </button>
          </div>
          <div class="bg-white">
            <table
                class="table"
                @keyup.alt.enter="addToRepeater"
                @keyup.alt.46="deleteFromRepeater(0)"
            >
              <thead>
              <tr>
                <th>
                  <span>{{ $t("customer") }}</span>
                  <small class="pr-2 d-inline-block text-muted ">{{$t("type_at_least_three_letters") + " " + $t("customer")}}.</small>
                </th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ $t("expenses_income_transaction.payment_method") }}
                    </span>
                  </div>
                </th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ $t("treasury_or_bank") }}
                    </span>
                  </div>
                </th>
                <th class="text-center" width="20px">
                  <span>{{ $t("actions") }}</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c, index) in customers_list" :key="index">
                <td>
                  <div class="input-group">
                    <multiselect
                        v-model="c.customer_obj"
                        :class="
                          validation && validation.customer ? 'is-invalid' : ''
                        "
                        :multiple="false"
                        :options="customers"
                        :placeholder="$t('customer')"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        :taggable="false"
                        label="name"
                        track-by="id"
                        @input="useCustomer($event, index)"
                        @search-change="getCustomers($event)"
                    >
                    </multiselect>
                  </div>
                  <span v-if="validation && validation.customer" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.customer[0] }}
                  </span>
                </td>
                <td>
                  <select
                      id="payment_method"
                      v-model="c.payment_method"
                      :class="validation && validation.payment_method ? 'is-invalid' : '' "
                      class="custom-select"
                      name=""
                      :placeholder="$t('expenses_income_transaction.payment_method')"
                      @change="setPaymentType($event, index)"
                  >
                    <option
                        v-for="row in payment_methods"
                        :key="row.id"
                        :value="row.id"
                    >
                      {{ row.title }}
                    </option>
                  </select>
                  <span
                      v-if="validation && validation.payment_method"
                      class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.payment_method[0] }}
                  </span>
                </td>
                <td>
                  <div v-if="c.method_type_type === 'Bank'">
                    <div class="input-group">
                      <multiselect v-model="c.banks_obj"
                                   :class="validation && validation.method_type_id ? 'is-invalid' : ''"
                                   :multiple="false"
                                   :options="banks"
                                   :placeholder="$t('expenses.bank')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id"
                                   @input="useBank($event, index)"
                                   @search-change="getBanks($event)">
                      </multiselect>
                    </div>
                    <small class="form-text text-muted">{{
                        $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks')
                      }}.</small>
                    <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.type_id[0] }}
                    </span>
                  </div>
                  <div v-else-if="c.method_type_type === 'Treasuries'">
                    <div class="input-group">
                      <multiselect v-model="c.treasury_obj"
                                   :class="validation && validation.type_id ? 'is-invalid' : ''"
                                   :multiple="false"
                                   :options="treasuries"
                                   :placeholder="$t('expenses.treasury')"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :taggable="false"
                                   label="name"
                                   track-by="id"
                                   @input="useTreasury($event, index)">
                      </multiselect>
                    </div>
                    <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.type_id[0] }}
                    </span>
                  </div>
                </td>

                <td class="text-center">
                  <v-icon
                      class="text-danger"
                      @click="deleteFromRepeater(index)"
                  >mdi-delete
                  </v-icon
                  >
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button class="btn btn-primary mr-2" type="button" @click="save">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "AddSubscriber",
  data() {
    return {
      mainRouteDependency: "base/dependency",
      subscription_id: this.$route.params.id ? this.$route.params.id : null,
      subscription_data: null,
      customers: [],
      banks: [],
      payment_methods: [],
      treasuries: [],
      customers_list: [
        {
          treasury_obj: null,
          customer_obj: null,
          banks_obj: null,
          payment_method: null,
          method_type_type: null,
          method_type_id: null,
          amount: 0,
          customer: {
            id: null,
            name: null,
          },
        },
      ],
      customers_repeater: {
        treasury_obj: null,
        customer_obj: null,
        banks_obj: null,
        payment_method: null,
        method_type_type: null,
        method_type_id: null,
        amount: 0,
        customer: {
          id: null,
          name: null,
        },
      },
      validation: null,
    };
  },
  methods: {
    getCustomers: _.debounce(function (filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {
          params: {filter: filter},
        }).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    }, 500),
    getBanks: _.debounce(function (filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    }, 500),
    setPaymentType(event, index) {
      switch (event.target.value) {
        case "1":
        case "2":
          this.customers_list[index].method_type_type = "Treasuries";
          break;
        case "3":
          this.customers_list[index].method_type_type = "Bank";
          break;
        case "6":
          this.customers_list[index].method_type_type = "CustomerBalances";
          break;
        default:
          this.customers_list[index].method_type_type = null;
          break;
      }
    },
    useCustomer(customer, index) {
      this.customers_list[index].customer.id = customer.id;
      this.customers_list[index].customer.name = customer.name;
    },
    useBank(bank, index) {
      this.customers_list[index].method_type_id = bank.id;
    },
    useTreasury(treasury, index) {
      this.customers_list[index].method_type_id = treasury.id;
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getSubscriptionData() {
      ApiService.get(`pos/laundry_subscriptions/${this.subscription_id}`).then(
          (response) => {
            this.subscription_data = response.data.data;
          }
      );
    },
    addToRepeater() {
      this.customers_list.unshift(this.customers_repeater);
      this.customers_repeater = {
        treasury_obj: null,
        customer_obj: null,
        banks_obj: null,
        payment_method: null,
        method_type_type: null,
        method_type_id: null,
        amount: 0,
        customer: {
          id: null,
          name: null,
        },
      };
    },
    deleteFromRepeater(index) {
      this.customers_list.splice(index, 1);
    },
    save() {
      // loop on customers_list and chane amount to subscription_data.price and delete customer_obj and banks_obj and treasury_obj
      this.customers_list.forEach((customer) => {
        customer.amount = this.subscription_data.price;
        delete customer.customer_obj;
        delete customer.banks_obj;
        delete customer.treasury_obj;
      });
      ApiService.patch(`pos/laundry_subscription/customers_subscription/${this.subscription_id}`, {
        customers: this.customers_list,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
        this.$errorAlert(error);
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.laundry_subscriptions"), route: '/pos/laundry-subscriptions'},{title: this.$t("add_subscribers")}]);
  },
  created() {
    this.getPaymentMethods();
    this.getSubscriptionData();
    this.getTreasuries();
  }
};
</script>
